.App {
  text-align: center;
  color: #c285ff;
  background-color: #005;
}

.App-logo {
  height: 120px;
  border-radius: 50%;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
}

.App-link {
  color: inherit;
  padding: .4em 2em;
  border: 1px solid #c285ff;
  border-radius: 1em;
  text-decoration: none;
  min-width: 180px;
  display: block;
}

.App-link:hover,
.App-link:focus,
.App-link:active
 {
   background: #c285ff2d;
 }
.App-link-wrap {
  padding: 0.7rem;
}
